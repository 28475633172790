import React from 'react';
import {
  InFrameBannerListPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/InFrameBanner/InFrameBannerList/presenter';

export type Props = PresenterProps;

export const InFrameBannerList: React.FC<Props> = ({ list, column, type }) => (
  <InFrameBannerListPresenter list={list} column={column} type={type} />
);
