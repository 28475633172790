import React from 'react';
import classNames from 'classnames';
import { Point } from 'js/components/common/Point/Point';
import { Button } from 'js/components/ui/Button/Button';
import {
  STATUS_COMPLETED,
  STATUS_LASTBONUS,
  STATUS_PENDING,
} from 'js/components/specific/top/FirstEnquete/const';

type QuestionType = {
  id: string;
  type?: 'bonus' | 'enquete';
  mission_id?: string;
  point_id?: string;
  visit_incentive_key?: string;
  point: string;
  number?: string;
  title: string;
  has_answered: boolean;
  can_answer?: boolean;
};

export type Props = {
  isNewUser: boolean;
  status?: number;
  questions?: QuestionType[];
  nextQuestionStartHour?: string;
  nextQuestionHourLeft?: string;
  nextBonusHourLeft?: string;
  isBonus?: boolean;
  sessionIdHash: string;
};

export const FirstEnquetePresenter: React.FC<Props> = ({
  isNewUser,
  status,
  questions = [],
  nextQuestionStartHour,
  nextQuestionHourLeft,
  nextBonusHourLeft,
  isBonus,
  sessionIdHash,
}) => {
  if (!isNewUser) {
    return null;
  }

  // アンケートとボーナスの情報を分ける
  const enqueteList = questions.filter((q) => q.type !== 'bonus');
  const bonus = questions.filter((q) => q.type === 'bonus')[0];

  // タイトル（1日目）
  const titleDay1Element = isBonus ? (
    <>
      <h2 className="first-enquete__heading is-bonus">
        <span className="first-enquete__heading-label">
          {status === STATUS_PENDING || status === STATUS_LASTBONUS ? (
            <>すべて回答済みです！</>
          ) : (
            <>
              回答期限
              <span className="first-enquete__heading-term">
                {nextQuestionHourLeft}
              </span>
            </>
          )}
        </span>
        <br />
        アンケート回答で
        <Point point={600} style="normal" sizeType="relative" />
        ボーナス
      </h2>
      <p className="first-enquete__description c_simple_notation">
        対象アンケート2回答ごとに
        <strong>
          100ポイント、全10回答で600ポイントをボーナスでプレゼント!
        </strong>
      </p>
    </>
  ) : null;

  // タイトル（2日目以降）
  const titleAfterDay2Element = !isBonus ? (
    <>
      <h2 className="first-enquete__heading is-not-bonus">
        さっそくアンケートで稼ごう！
      </h2>
      <p className="first-enquete__description">
        最初にオススメのアンケートに答えよう！
      </p>
    </>
  ) : null;

  // アンケート一覧
  const enqueteListElement = enqueteList ? (
    <ul
      className={classNames(
        'first-enquete__list',
        `column-${enqueteList.length}`,
      )}
    >
      {enqueteList.map((question) => (
        <li className="first-enquete__item enquete-item" key={question.id}>
          <a
            className="enquete-item__link c_reset c_blocklink c_hover-pop"
            href={
              question.has_answered ? null : `/research/fastenq/${question.id}`
            }
          >
            <div className="enquete-item__left-wrapper">
              <p className="enquete-item__label">ECナビアンケート</p>
              <p className="enquete-item__title">{question.title}</p>
              <Point
                className={classNames(
                  'enquete-item__label enquete-item__point',
                )}
                point={Number(question.point)}
                sizeType="relative"
              />
              <p className="enquete-item__detail">
                <span>設問数: {question.number}問</span>
                <span>加算時期: 回答後3分以内</span>
              </p>
            </div>
            <div className="enquete-item__right-wrapper">
              <Button
                className="enquete-item__button"
                width={70}
                isPrimary={!question.has_answered}
                isDisabled={question.has_answered}
              >
                回答
                <br />
                {question.has_answered ? '済み' : 'する'}
              </Button>
            </div>
          </a>
        </li>
      ))}
    </ul>
  ) : null;

  // ボーナス獲得（未回答）
  const getBonusNotAnsweredElement =
    bonus && !bonus.can_answer ? (
      <div className="bonus-point-item__wrapper">
        <p className="bonus-point-item__text">
          アンケート2回答で
          <Point point={Number(bonus.point)} />
        </p>

        <Button isDisabled={true}>ボーナスポイントGET!</Button>
        <p className="bonus-point-item__term">
          ※獲得期限 : {nextQuestionStartHour}
        </p>
      </div>
    ) : null;

  // ボーナス獲得（回答済み）
  const getBonusAnsweredElement =
    bonus && bonus.can_answer ? (
      <div className="bonus-point-item__link">
        <p className="bonus-point-item__text">
          ボタン押して
          <Point point={Number(bonus.point)} />
        </p>
        <form
          id="answerForm"
          action={`/research/fastenq/${bonus.id}/answer`}
          name="submit_bonus"
          method="post"
        >
          <input type="hidden" name="ck" value={sessionIdHash} />
          <Button type="submit" isPrimary={true}>
            ボーナスポイントGET!
          </Button>
        </form>
        {status === STATUS_LASTBONUS && (
          <p className="bonus-point-item__term">
            ※獲得期限 : {nextQuestionStartHour}
          </p>
        )}
      </div>
    ) : null;

  // STATUS_PENDING 全10問回答済み
  const statusPendingElement =
    status === STATUS_PENDING ? (
      <>
        <div className="complete-enquete">
          <p className="complete-enquete__text">
            <Point point={200} />
            プレゼントまで
            {nextBonusHourLeft}！
          </p>
          <Button
            className="complete-enquete__button"
            isDisabled={true}
            type="submit"
          >
            ボーナスポイントGET!
          </Button>
        </div>
      </>
    ) : null;

  // STATUS_COMPLETED アンケート回答完了の状態
  const statusCompletedElement =
    status === STATUS_COMPLETED ? (
      <div className="complete-enquete">
        <p className="complete-enquete__text c_simple_notation">
          アンケートは全て回答済みです。
          {nextQuestionHourLeft && (
            <>
              <br />
              <strong>次のアンケートは{nextQuestionStartHour}に追加！</strong>
              <br />
              お見逃しなく！
            </>
          )}
        </p>
      </div>
    ) : null;

  return (
    <section className="first-enquete">
      {titleDay1Element}
      {titleAfterDay2Element}
      {enqueteListElement}
      {getBonusNotAnsweredElement}
      {getBonusAnsweredElement}
      {statusPendingElement}
      {statusCompletedElement}
    </section>
  );
};
