import React from 'react';
import { BokinCampaign } from 'js/components/specific/top/DisasterSupport/type';

export type Props = {
  bokinCampaign: BokinCampaign[];
};

export const DisasterSupportPresenter: React.FC<Props> = ({
  bokinCampaign,
}) => (
  <>
    {bokinCampaign.length > 0 && (
      <section className="disaster-support">
        <h2 className="disaster-support__heading c_heading-2">災害募金</h2>
        <ul className="disaster-support__list c_contents-group">
          {bokinCampaign.map((item) => (
            <li className="disaster-support__item" key={item.slug}>
              <a
                className="disaster-support__link c_reset c_hover-pop"
                href={`/smile_project/bokin/${item.slug}/?referrer=top--disaster-support`}
              >
                <p className="disaster-support__title">
                  {item.upperImageText}
                  <br />
                  {item.lowerImageText}
                </p>
                <p className="disaster-support__lead">寄付金を集めています</p>
              </a>
            </li>
          ))}
        </ul>
      </section>
    )}
  </>
);
