import React from 'react';
import classNames from 'classnames';
import { AffiliateItem } from 'js/components/common/Affiliate/AffiliateItem';
import { AffiliateItemType } from 'js/types/affiliate';
import { Point } from 'js/components/common/Point/Point';

export type Props = {
  id: number;
  startAt: string;
  endAt: string;
  achievementCount: number;
  achievementPoint: number;
  achievedCount: number;
  isStarted: boolean;
  isAchieved: boolean;
  isFinished: boolean;
  viewType?: 'top' | 'point-up-challenge';
  affiliateItem: Omit<AffiliateItemType, 'createdAt'>;
};

export const PointUpChallengeItemPresenter: React.FC<Props> = ({
  id,
  endAt,
  achievementCount,
  achievementPoint,
  achievedCount,
  isAchieved,
  isFinished,
  viewType = 'point-up-challenge',
  affiliateItem,
}) => {
  const tagInfo = (() => {
    if (isFinished && isAchieved) {
      return {
        type: 'expired',
        text: '達成\n終了',
      };
    } else if (isFinished && !isAchieved) {
      return {
        type: 'expired',
        text: '失敗\n終了',
      };
    } else if (isAchieved) {
      return {
        type: 'achieved',
        text: '達成',
      };
    } else {
      return {
        type: 'challenge',
        text: `${achievedCount.toLocaleString()}/${achievementCount.toLocaleString()}`,
      };
    }
  })();
  return (
    <AffiliateItem
      className={classNames('point-up-challenge-list__item', {
        'view-top': viewType === 'top',
      })}
      {...{
        ...affiliateItem,
        linkUrl: `/contents/point_up_challenge/detail/?id=${id}`,
      }}
    >
      <>
        {viewType === 'point-up-challenge' && (
          <span
            className={classNames(
              'point-up-challenge-list__tag',
              `type-${tagInfo.type}`,
            )}
          >
            {tagInfo.text}
          </span>
        )}
        <div className="point-up-challenge-list__reward-wrapper">
          <span className="point-up-challenge-list__objective">
            {achievementCount.toLocaleString()}人達成で
          </span>
          <span className="point-up-challenge-list__reward">
            <Point point={achievementPoint} prefix="+" />
          </span>
          <span className="point-up-challenge-list__term">{endAt}まで</span>
        </div>
      </>
    </AffiliateItem>
  );
};
