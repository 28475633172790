import React from 'react';
import {
  BannerItemPresenter,
  Props as PresenterProps,
} from 'js/components/common/Banner/BannerItem/presenter';

export type Props = PresenterProps;

export const BannerItem: React.FC<Props> = ({
  linkUrl,
  isTargetBlank,
  imageUrl,
  title,
  type,
  baseClassName,
}) => (
  <BannerItemPresenter
    linkUrl={linkUrl}
    isTargetBlank={isTargetBlank}
    imageUrl={imageUrl}
    title={title}
    type={type}
    baseClassName={baseClassName}
  />
);
