import React from 'react';
import {
  ChomedamaPopupPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Chomedama/ChomedamaPopup/presenter';

export type Props = PresenterProps;

export const ChomedamaPopup: React.FC<Props> = ({ ...props }) => (
  <ChomedamaPopupPresenter {...props} />
);
