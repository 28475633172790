import { useState, useEffect } from 'react';

export const useLocalStorage = <T>(key: string) => {
  const [isReady, setIsReady] = useState(false);
  const [value, setValue] = useState<T>();

  useEffect(() => {
    try {
      const item = localStorage.getItem(key);
      setValue(item ? (JSON.parse(item) as T) : undefined);
    } catch (e) {
      setValue(undefined);
    }
    setIsReady(true);
  }, [key]);

  const save = (
    newValue: T,
    typeValidateCallback?: (data: unknown) => boolean,
  ) => {
    if (typeValidateCallback && !typeValidateCallback(newValue)) {
      return;
    }
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  return { value, save, isReady };
};
