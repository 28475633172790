import React from 'react';
import classNames from 'classnames';
import { BannerType } from 'js/components/common/Banner/BannerItem/type';

export const bannerSizeMap = {
  square: {
    width: 200,
    height: 200,
  },
  wide: {
    width: 650,
    height: 260,
  },
};

export type Props = {
  linkUrl: string;
  isTargetBlank?: boolean;
  imageUrl: string;
  title?: string;
  type?: BannerType;
  baseClassName?: string;
};

export const BannerItemPresenter: React.FC<Props> = ({
  linkUrl,
  isTargetBlank,
  imageUrl,
  title = '',
  type = 'square',
  baseClassName,
}) => {
  const blank = isTargetBlank ? { target: '_blank', rel: 'noopener' } : {};
  return (
    <div className={classNames({ [`${baseClassName}__item`]: baseClassName })}>
      <a
        className={classNames(
          { [`${baseClassName}__link`]: baseClassName },
          'c_blocklink',
          'c_hover-pop',
        )}
        href={linkUrl}
        {...blank}
      >
        <img
          className={classNames({ [`${baseClassName}__image`]: baseClassName })}
          src={imageUrl}
          alt={title}
          title={title}
          width={bannerSizeMap[type].width}
          height={bannerSizeMap[type].height}
        />
      </a>
    </div>
  );
};
