import React from 'react';
import classNames from 'classnames';
import {
  Props as PointUpChallengeItemProps,
  PointUpChallengeItem,
} from 'js/components/common/Affiliate/PointUpChallengeItem';

export type Props = {
  list?: PointUpChallengeItemProps[];
  viewType?: 'top' | 'point-up-challenge';
};

export const PointUpChallengeListPresenter: React.FC<Props> = ({
  list,
  viewType,
}) => (
  <>
    {list && (
      <ul
        className={classNames('point-up-challenge-list', 'p_item-list__list', {
          'view-top': viewType === 'top',
        })}
      >
        {list.map((item) => (
          <PointUpChallengeItem {...item} viewType={viewType} key={item.id} />
        ))}
      </ul>
    )}
  </>
);
