import React from 'react';
import { Tabs, TabsProps } from 'react-tabs';

export type Props = Omit<
  TabsProps,
  | 'direction'
  | 'disabledTabClassName'
  | 'selectedTabClassName'
  | 'selectedTabPanelClassName'
>;

export const TabsPresenter: React.FC<Props> = ({ children, ...props }) => (
  <Tabs {...props}>{children}</Tabs>
);
