import React from 'react';
import { TabPanel, TabPanelProps } from 'react-tabs';

export type Props = TabPanelProps;

export const TabPanelPresenter: React.FC<Props> = ({
  className,
  forceRender,
  selectedClassName,
  children,
  ...props
}) => (
  <TabPanel
    className={className}
    forceRender={forceRender}
    selectedClassName={selectedClassName}
    {...props}
  >
    {children}
  </TabPanel>
);
