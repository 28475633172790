import React from 'react';
import { SlideBannerThumbnailList } from 'js/components/specific/top/SlideBanner/SlideBannerThumbnailList';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlideBannerAffiliateItem } from 'js/components/specific/top/SlideBanner/SlideBannerAffiliateItem';
import { SlideBannerImageItem } from 'js/components/specific/top/SlideBanner/SlideBannerImageItem';
import { AffiliateItemType } from 'js/types/affiliate';
import { useSlideBanner } from 'js/components/specific/top/SlideBanner/SlideBanner/hooks/useSlideBanner';
import { MAX_ITEM_COUNT } from 'js/components/specific/top/SlideBanner/SlideBanner/const';

export type Props = {
  list: AffiliateItemType[];
};

export const SlideBannerPresenter: React.FC<Props> = ({ list }) => {
  const { slideBannerList, slider, settings } = useSlideBanner(list);

  return (
    <div className="p_slide-banner">
      <div className="p_slide-banner__wrapper">
        {list.length === 1 ? (
          list[0].unit ? (
            <SlideBannerAffiliateItem
              {...list[0]}
              key={list[0].itemName}
              index={0}
            />
          ) : (
            <SlideBannerImageItem
              {...list[0]}
              key={list[0].itemName}
              index={0}
            />
          )
        ) : (
          <Slider {...settings} ref={slider}>
            {slideBannerList.map(
              (item, index) =>
                index < MAX_ITEM_COUNT &&
                (item.unit ? (
                  <SlideBannerAffiliateItem {...item} key={item.itemName} />
                ) : (
                  <SlideBannerImageItem {...item} key={item.itemName} />
                )),
            )}
          </Slider>
        )}
      </div>
      {list.length > 1 && <SlideBannerThumbnailList list={slideBannerList} />}
    </div>
  );
};
