import React from 'react';
import classNames from 'classnames';
import {
  BannerItem,
  Props as BannerItemProps,
} from 'js/components/common/Banner/BannerItem';
import { ColumnType } from 'js/types/gridList';
import { BannerType } from 'js/components/common/Banner/BannerItem/type';

const baseClassName = 'in-frame-banner';

export type Props = {
  list: BannerItemProps[];
  column?: ColumnType;
  type?: BannerType;
};

export const InFrameBannerListPresenter: React.FC<Props> = ({
  list,
  column = 'auto',
  type,
}) => (
  <div
    className={classNames(
      baseClassName,
      'c_contents-group',
      'p_grid-list',
      `column-${column}`,
      `type-${type}`,
    )}
  >
    {list.map((item) => (
      <BannerItem {...item} {...{ type, baseClassName }} key={item.imageUrl} />
    ))}
  </div>
);
