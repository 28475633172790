import React from 'react';
import {
  ChomedamaPanelPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Chomedama/ChomedamaPanel/presenter';

export type Props = PresenterProps;

export const ChomedamaPanel: React.FC<Props> = (props) => (
  <ChomedamaPanelPresenter {...props} />
);
