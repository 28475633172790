import React from 'react';
import {
  IdentificationNoticePresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/IdentificationNotice/presenter';

export type Props = PresenterProps;

export const IdentificationNotice: React.FC<Props> = ({
  isChanged,
  isWithinWaitingTerm,
  isWithinValidTerm,
  isRegisteredPhone,
  isExpiredTerm,
  canShowExpiredMessage,
  canShow2faMessage,
  emailChangeDate,
}) => (
  <IdentificationNoticePresenter
    isChanged={isChanged}
    isWithinWaitingTerm={isWithinWaitingTerm}
    isWithinValidTerm={isWithinValidTerm}
    isRegisteredPhone={isRegisteredPhone}
    isExpiredTerm={isExpiredTerm}
    canShowExpiredMessage={canShowExpiredMessage}
    canShow2faMessage={canShow2faMessage}
    emailChangeDate={emailChangeDate}
  />
);
