import React from 'react';
import classNames from 'classnames';
import { AffiliateItemType } from 'js/types/affiliate';

export type Props = Pick<
  AffiliateItemType,
  'linkUrl' | 'imageUrl' | 'itemName' | 'unit'
> & {
  index: number;
  isActive?: boolean;
  slickGoToCurrent?: () => void;
};

export const SlideBannerThumbnailItemPresenter: React.FC<Props> = ({
  unit,
  imageUrl,
  linkUrl,
  itemName,
  index,
  isActive,
  slickGoToCurrent,
}) => (
  <li
    className={classNames(
      'p_slide-banner__thumbnail-item',
      `frame-${index + 1}`,
      { 'slick-active': isActive },
    )}
  >
    <a
      className={classNames(
        'p_slide-banner__thumbnail-button',
        `type-${unit ? 'affiliate' : 'image'}`,
      )}
      href={linkUrl}
      onClick={(e) => {
        if (!isActive) {
          e.preventDefault();
        }
        slickGoToCurrent();
      }}
    >
      <div className="p_slide-banner__thumbnail-wrapper">
        <img
          className="p_slide-banner__thumbnail-image"
          src={imageUrl}
          alt={itemName}
          width={unit ? 200 : 650}
          height={unit ? 200 : 260}
        />
      </div>
    </a>
  </li>
);
