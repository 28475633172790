import React from 'react';

export const IdentificationNoticeExpiredPresenter: React.FC = () => (
  <div className="identification-step c_emphasis-info">
    <p className="c_emphasis-info__heading">メールアドレス変更可能期間終了</p>
    <p className="c_emphasis-info__message">
      メールアドレス変更が必要な場合は下記からお問い合わせください。
    </p>
    <a href="/support/?referrer=top--identification" className="c_button">
      アカウントに関するお問い合わせ
    </a>
  </div>
);
