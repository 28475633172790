import React from 'react';
import {
  SlideBannerThumbnailItemPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/SlideBanner/SlideBannerThumbnailItem/presenter';

export type Props = PresenterProps;

export const SlideBannerThumbnailItem: React.FC<Props> = ({
  unit,
  imageUrl,
  linkUrl,
  itemName,
  index,
  isActive,
  slickGoToCurrent,
}) => (
  <SlideBannerThumbnailItemPresenter
    unit={unit}
    imageUrl={imageUrl}
    linkUrl={linkUrl}
    itemName={itemName}
    index={index}
    isActive={isActive}
    slickGoToCurrent={slickGoToCurrent}
  />
);
