import React from 'react';
import classNames from 'classnames';
import { AffiliateItemType } from 'js/types/affiliate';
import { useHandleSlideEvent } from 'js/components/specific/top/SlideBanner/SlideBannerAffiliateItem/hooks/useHandleSlideEvent';

export type Props = Pick<
  AffiliateItemType,
  'linkUrl' | 'imageUrl' | 'itemName'
> & {
  index: number;
  isActive?: boolean;
  slickGoToCurrent?: () => void;
};

export const SlideBannerImageItemPresenter: React.FC<Props> = ({
  index,
  linkUrl,
  imageUrl,
  itemName,
  isActive,
  slickGoToCurrent,
}) => {
  const { handleOnMouseDown, handleOnClick } = useHandleSlideEvent(
    isActive,
    slickGoToCurrent,
  );
  const isSingle = typeof slickGoToCurrent === 'undefined';

  const handleOnMouseDownWhenMultiple: React.MouseEventHandler<
    HTMLAnchorElement
  > = (e) => {
    if (isSingle) {
      return;
    }
    handleOnMouseDown(e);
  };

  const handleOnClickWhenMultiple: React.MouseEventHandler<
    HTMLAnchorElement
  > = (e) => {
    if (isSingle) {
      return;
    }
    handleOnClick(e);
  };

  return (
    <div
      className={classNames(
        'p_slide-banner__item',
        'type-image',
        `frame-${index + 1}`,
      )}
    >
      <a
        className="p_slide-banner__link"
        href={linkUrl}
        onMouseDown={handleOnMouseDownWhenMultiple}
        onClick={handleOnClickWhenMultiple}
      >
        <img
          className="p_slide-banner__image"
          src={imageUrl}
          alt={itemName}
          width="650"
          height="260"
        />
      </a>
    </div>
  );
};
