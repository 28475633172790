import React from 'react';
import {
  PointUpChallengeListPresenter,
  Props as PresenterProps,
} from 'js/components/common/Affiliate/PointUpChallengeList/presenter';

export type Props = PresenterProps;

export const PointUpChallengeList: React.FC<Props> = (props) => (
  <PointUpChallengeListPresenter {...props} />
);
