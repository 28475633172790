import { useState, useEffect } from 'react';

type WindowSize = {
  width?: number;
  height?: number;
};

/**
 * ウィンドウサイズを取得するHook
 *
 * @return {WindowSize} ウィンドウサイズ
 */
const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
