import React from 'react';
import classNames from 'classnames';
import { AffiliateItemType } from 'js/types/affiliate';
import { useScrollVisible } from 'js/hooks/useScrollVisible';
import { PointPattern } from 'js/components/common/Point/PointPattern';

export type Props = {
  item: AffiliateItemType;
  featureText?: string;
  isOpen: boolean;
  onToggleCollapse: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ChomedamaPanelPresenter: React.FC<Props> = ({
  item,
  featureText,
  isOpen,
  onToggleCollapse,
}) => {
  const isVisible = useScrollVisible();
  return (
    <div
      className={classNames('chomedama-panel', {
        'panel-collapse': isVisible && !isOpen,
        'is-visible': isVisible,
      })}
    >
      <div className="chomedama-panel__button-wrapper">
        <button
          className="chomedama-panel__button"
          type="button"
          onClick={onToggleCollapse}
        >
          <img
            className="chomedama-panel__logo"
            src="/top/img/chomedama/panel_title.svg"
            alt="超目玉"
            width="34"
            height="70"
          />
          <span className="chomedama-panel__arrow" />
        </button>
      </div>
      <a
        className={classNames('chomedama-panel__link', 'c_hover-pop')}
        href={item.linkUrl}
      >
        <div className="chomedama-panel__detail-wrapper">
          {featureText && (
            <p className="chomedama-panel__feature-text">{featureText}</p>
          )}
          <div
            className={classNames(
              'chomedama-panel__inner-frame',
              `${item.unit ? 'type-affiliate' : 'type-banner'}`,
            )}
          >
            {item.unit ? (
              /* アフィリエイト案件 */
              <>
                <div className="chomedama-panel__image-wrapper">
                  <img
                    className="chomedama-panel__image"
                    src={item.imageUrl}
                    alt={item.itemName}
                    width="200"
                    height="200"
                  />
                </div>
                <div className="chomedama-panel__detail">
                  <p className="chomedama-panel__name">{item.itemName}</p>
                  <PointPattern
                    baseClassName="chomedama-panel"
                    regularPoint={
                      item.unit === 'rate'
                        ? item.regularPoint
                        : (item.campaignPoint ?? item.regularPoint)
                    }
                    campaignPoint={item.unit === 'rate' && item.campaignPoint}
                    unit={item.unit}
                    sizeType={'relative'}
                    beforeSize={'medium'}
                    afterSize={'x-large'}
                  />
                </div>
              </>
            ) : (
              /* バナーのみ */
              <>
                <img
                  className="chomedama-panel__wide-image"
                  src={item.imageUrl}
                  alt={item.itemName}
                  width="650"
                  height="260"
                />
              </>
            )}
          </div>
        </div>
      </a>
    </div>
  );
};
