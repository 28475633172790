import React from 'react';
import {
  InFrameBannerSkeletonListPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/InFrameBanner/InFrameBannerSkeletonList/presenter';

export type Props = PresenterProps;

export const InFrameBannerSkeletonList: React.FC<Props> = ({
  count,
  column,
  type,
}) => (
  <InFrameBannerSkeletonListPresenter
    count={count}
    column={column}
    type={type}
  />
);
