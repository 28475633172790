import React from 'react';
import {
  SlideBannerAffiliateItemPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/SlideBanner/SlideBannerAffiliateItem/presenter';

export type Props = PresenterProps;

export const SlideBannerAffiliateItem: React.FC<Props> = ({
  linkUrl,
  imageUrl,
  itemName,
  condition,
  campaignPoint,
  regularPoint,
  unit,
  index,
  isActive,
  slickGoToCurrent,
}) => (
  <SlideBannerAffiliateItemPresenter
    linkUrl={linkUrl}
    imageUrl={imageUrl}
    itemName={itemName}
    condition={condition}
    campaignPoint={campaignPoint}
    regularPoint={regularPoint}
    unit={unit}
    index={index}
    isActive={isActive}
    slickGoToCurrent={slickGoToCurrent}
  />
);
