import React from 'react';
import { CategoryBasedRanking } from 'js/types/ranking';
import { ColumnType } from 'js/types/gridList';
import { Tab, TabList, TabPanel, Tabs } from 'js/components/ui/Tab';
import { AffiliateItem } from 'js/components/common/Affiliate/AffiliateItem';
import classNames from 'classnames';

export type Props = {
  list: CategoryBasedRanking[];
  column?: Omit<ColumnType, 'auto'>;
  isFadeIn?: boolean;
  isGrid?: boolean;
};

export const RankingPresenter: React.FC<Props> = ({
  list,
  column,
  isFadeIn,
  isGrid,
}) => {
  return (
    <div className="p_affiliate-ranking">
      <Tabs>
        <TabList>
          {list.map((category) => (
            <Tab key={category.categoryName}>{category.categoryName}</Tab>
          ))}
        </TabList>
        {list.map((category) => (
          <TabPanel key={category.categoryName}>
            <ul
              className={classNames(
                'c_contents-group',
                'p_item-list__list',
                { [`column-${String(column)}`]: column },
                'u_bg-white',
                { 'is-fade-in': isFadeIn },
                { 'is-grid': isGrid },
              )}
            >
              {category.items.map((item, index) => (
                <AffiliateItem
                  {...item}
                  rankNumber={index + 1}
                  key={item.linkUrl}
                />
              ))}
            </ul>
          </TabPanel>
        ))}
      </Tabs>
      <p className="p_affiliate-ranking__attention">
        ※ランキングは前日までの広告利用の結果を集計したものです。
      </p>
    </div>
  );
};
