import React from 'react';
import { ReactTabsFunctionComponent } from 'react-tabs';
import {
  TabPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Tab/Tab/presenter';

export type Props = PresenterProps;

export const Tab: ReactTabsFunctionComponent<Props> = ({
  children,
  ...props
}) => <TabPresenter {...props}>{children}</TabPresenter>;
Tab.tabsRole = 'Tab';
