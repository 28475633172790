import React from 'react';
import {
  SlideBannerImageItemPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/SlideBanner/SlideBannerImageItem/presenter';

export type Props = PresenterProps;

export const SlideBannerImageItem: React.FC<Props> = ({
  index,
  linkUrl,
  imageUrl,
  itemName,
  isActive,
  slickGoToCurrent,
}) => (
  <SlideBannerImageItemPresenter
    index={index}
    linkUrl={linkUrl}
    imageUrl={imageUrl}
    itemName={itemName}
    isActive={isActive}
    slickGoToCurrent={slickGoToCurrent}
  />
);
