import React from 'react';
import {
  AppealFramePresenter,
  Props as PresenterProps,
} from 'js/components/ui/AppealFrame/presenter';

export type Props = PresenterProps;

export const AppealFrame: React.FC<Props> = ({
  className,
  heading,
  children,
  contentsLink,
}) => (
  <AppealFramePresenter
    className={className}
    heading={heading}
    contentsLink={contentsLink}
  >
    {children}
  </AppealFramePresenter>
);
