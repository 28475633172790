import { useState } from 'react';

export const useAlreadyRead = (key: string) => {
  const MAX_LENGTH = 100;
  const [ids, setIds] = useState<string[]>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as string[]) : [];
    } catch (e) {
      return [];
    }
  });
  const save = (id: string) => {
    const newIds = [id, ...ids].slice(0, MAX_LENGTH);
    setIds(newIds);
    localStorage.setItem(key, JSON.stringify(newIds));
  };
  return { ids, save };
};
