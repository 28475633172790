import React from 'react';
import classNames from 'classnames';
import { PointPattern } from 'js/components/common/Point/PointPattern';
import { AffiliateItemType } from 'js/types/affiliate';
import { useHandleSlideEvent } from 'js/components/specific/top/SlideBanner/SlideBannerAffiliateItem/hooks/useHandleSlideEvent';

export type Props = Pick<
  AffiliateItemType,
  | 'linkUrl'
  | 'imageUrl'
  | 'itemName'
  | 'condition'
  | 'campaignPoint'
  | 'regularPoint'
  | 'unit'
> & {
  index: number;
  isActive?: boolean;
  slickGoToCurrent?: () => void;
};

export const SlideBannerAffiliateItemPresenter: React.FC<Props> = ({
  linkUrl,
  imageUrl,
  itemName,
  condition,
  campaignPoint,
  regularPoint,
  unit,
  index,
  isActive,
  slickGoToCurrent,
}) => {
  const { handleOnMouseDown, handleOnClick } = useHandleSlideEvent(
    isActive,
    slickGoToCurrent,
  );
  const isSingle = typeof slickGoToCurrent === 'undefined';

  const handleOnMouseDownWhenMultiple: React.MouseEventHandler<
    HTMLAnchorElement
  > = (e) => {
    if (isSingle) {
      return;
    }
    handleOnMouseDown(e);
  };

  const handleOnClickDownWhenMultiple: React.MouseEventHandler<
    HTMLAnchorElement
  > = (e) => {
    if (isSingle) {
      return;
    }
    handleOnClick(e);
  };

  return (
    <div
      className={classNames(
        'p_slide-banner__item',
        'type-affiliate',
        `frame-${index + 1}`,
      )}
    >
      <a
        className="p_slide-banner__link p_slide-banner-content"
        href={linkUrl}
        onMouseDown={handleOnMouseDownWhenMultiple}
        onClick={handleOnClickDownWhenMultiple}
      >
        <div className="p_slide-banner-content__image-wrapper">
          <img
            className="p_slide-banner-content__image"
            src={imageUrl}
            alt={itemName}
            width="200"
            height="200"
          />
        </div>
        <div className="p_slide-banner-content__wrapper">
          <div className="p_slide-banner-content__name-wrapper">
            <p className="p_slide-banner-content__name">{itemName}</p>
          </div>
          <div className="p_slide-banner-content__info-wrapper">
            <div
              className={classNames('p_slide-banner-content__point-wrapper', {
                'is-many-digits':
                  regularPoint.toString().length +
                    (campaignPoint?.toString().length || 0) >
                  7, // regularPoint と campaignPoint の合計桁数が7以上の場合にデザイン調整
              })}
            >
              <PointPattern
                baseClassName="p_slide-banner-content"
                afterSize="xx-large"
                sizeType="relative"
                {...{ campaignPoint, regularPoint, unit }}
              />
            </div>
            <div className="p_slide-banner-content__condition">
              {condition ?? '条件クリア'}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
