import React from 'react';
import {
  SlideBannerThumbnailListPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/SlideBanner/SlideBannerThumbnailList/presenter';

export type Props = PresenterProps;

export const SlideBannerThumbnailList: React.FC<Props> = ({ list }) => (
  <SlideBannerThumbnailListPresenter list={list} />
);
