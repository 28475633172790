import React from 'react';
import { Tab, TabProps } from 'react-tabs';

export type Props = TabProps;

export const TabPresenter: React.FC<Props> = ({
  disabled,
  tabIndex,
  children,
  ...props
}) => (
  <Tab
    className="c_tab__label"
    selectedClassName="is-current"
    disabledClassName="is-disabled"
    disabled={disabled}
    tabIndex={tabIndex}
    {...props}
  >
    {children}
  </Tab>
);
