import React from 'react';
import {
  ChomedamaPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Chomedama/Chomedama/presenter';

export type Props = PresenterProps;

export const Chomedama: React.FC<Props> = ({ ...props }) => {
  return <ChomedamaPresenter {...props} />;
};
