import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { InformationElementType } from 'js/components/specific/top/Information/InformationList/type';

dayjs.extend(customParseFormat);

export const useExpirationInformation = (
  timestamp: string,
  limitPoint?: number,
  nextLimitDate?: string,
): InformationElementType | undefined => {
  const [expirationInformation, setExpirationInformation] = useState<
    InformationElementType | undefined
  >(undefined);

  useEffect(() => {
    const createExpirationInformation = (
      limitPointValue: number,
      nextLimitDateValue: string,
    ): InformationElementType | undefined => {
      const expireDate = dayjs(nextLimitDateValue, 'YYYY年M月D日H時', true);
      if (limitPointValue <= 0 || !expireDate.isValid()) {
        return undefined;
      }
      const displayStartDate = expireDate.subtract(1, 'month').startOf('day');
      const now = dayjs.unix(Number(timestamp));
      if (now.isBefore(displayStartDate)) {
        return undefined;
      }
      return {
        id: `point_expiration_${expireDate.format('YYYYMMDD')}`,
        children: `${limitPointValue.toLocaleString()}ポイントが失効予定です（${nextLimitDateValue}）`,
        linkUrl: '/mypage/point_limit/',
        type: 'info',
        canClose: true,
        publishAt: undefined,
      };
    };
    if (limitPoint && nextLimitDate) {
      setExpirationInformation(
        createExpirationInformation(limitPoint, nextLimitDate),
      );
    }
  }, [timestamp, limitPoint, nextLimitDate]);

  return expirationInformation;
};
