import React from 'react';
import { ChomedamaPopup } from 'js/components/specific/top/Chomedama/ChomedamaPopup';
import { ChomedamaPanel } from 'js/components/specific/top/Chomedama/ChomedamaPanel';
import { useChomedamaStatus } from 'js/components/specific/top/Chomedama/Chomedama/hooks/useChomedamaStatus';
import { EcnFrames } from 'js/const/ecnFrames';
import { ChomedamaItem } from 'js/components/specific/top/Chomedama/Chomedama/type';

export type Props = {
  item: ChomedamaItem;
};

const addEcnFrame = (url: string, id: number) => {
  const parseUrl = new URL(url);
  parseUrl.searchParams.set('ecnframe', id.toString());
  return parseUrl.toString();
};

export const ChomedamaPresenter: React.FC<Props> = ({ item }) => {
  const { status, save: updateStatus } = useChomedamaStatus(item.id);

  const handlePopupClose = (event: React.MouseEvent<HTMLElement>) => {
    updateStatus('panel-open');
    event.preventDefault();
  };
  const handlePanelToggleCollapse = () => {
    updateStatus(status === 'panel-open' ? 'panel-collapse' : 'panel-open');
  };

  return (
    <>
      <ChomedamaPopup
        item={{
          ...item.item,
          linkUrl: addEcnFrame(item.item.linkUrl, EcnFrames.CHOMEDAMA_POPUP),
        }}
        featureText={item.featureText}
        isOpen={status === 'popup'}
        onRequestClose={handlePopupClose}
      />

      {status != 'popup' && (
        <ChomedamaPanel
          item={{
            ...item.item,
            linkUrl: addEcnFrame(item.item.linkUrl, EcnFrames.CHOMEDAMA_PANEL),
          }}
          featureText={item.featureText}
          isOpen={status === 'panel-open'}
          onToggleCollapse={handlePanelToggleCollapse}
        />
      )}
    </>
  );
};
