import React from 'react';
import {
  SignUpAppealPresenter,
  Props as PresenterProps,
} from 'js/components/common/SignUpAppeal/presenter';

export type Props = PresenterProps;

export const SignUpAppeal: React.FC<Props> = ({ needSignupCID }) => (
  <SignUpAppealPresenter needSignupCID={needSignupCID} />
);
