import { useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { Props as SlideBannerAffiliateItemProps } from 'js/components/specific/top/SlideBanner/SlideBannerAffiliateItem';
import { AffiliateItemType } from 'js/types/affiliate';
import isChromatic from 'chromatic/isChromatic';

export const useSlideBanner = (list: AffiliateItemType[]) => {
  const AUTO_MOVE_SECOND = 5;
  const slider = useRef<Slider>();
  const [slideBannerList, setSlideBannerList] = useState<
    SlideBannerAffiliateItemProps[]
  >(
    list.map((l, i) => ({
      ...l,
      isActive: i === 0, // 初期値は0番目がアクティブ
      index: i,
      slickGoToCurrent: () => slider.current.slickGoTo(i),
    })),
  );

  useEffect(() => {
    setSlideBannerList(
      list.map((l, i) => ({
        ...l,
        isActive: i === 0, // 初期値は0番目がアクティブ
        index: i,
        slickGoToCurrent: () => slider.current.slickGoTo(i),
      })),
    );
  }, [list]);

  const settings: Settings = {
    arrows: false,
    // MEMO: Chromatic の実行タイミングによって差分が出るため、Chromatic 実行時は autoplay をオフにする
    autoplay: !isChromatic(),
    centerMode: true,
    variableWidth: true,
    centerPadding: '0',
    autoplaySpeed: AUTO_MOVE_SECOND * 1000,
    infinite: true,
    beforeChange: (current: number, next: number) => {
      // スライドしたら次に表示される要素がアクティブになるのでアクティブ要素を更新する
      setSlideBannerList(
        slideBannerList.map((l, i) => ({
          ...l,
          isActive: i === next,
        })),
      );
    },
  };

  return {
    slideBannerList,
    slider,
    settings,
  };
};
