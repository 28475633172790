import React from 'react';
import { ReactTabsFunctionComponent } from 'react-tabs';
import {
  TabListPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Tab/TabList/presenter';

export type Props = PresenterProps;

export const TabList: ReactTabsFunctionComponent<Props> = ({
  children,
  ...props
}) => <TabListPresenter {...props}>{children}</TabListPresenter>;
TabList.tabsRole = 'TabList';
