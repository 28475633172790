import React from 'react';
import {
  DisasterSupportPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/DisasterSupport/presenter';

export type Props = PresenterProps;

export const DisasterSupport: React.FC<Props> = ({ bokinCampaign }) => (
  <DisasterSupportPresenter bokinCampaign={bokinCampaign} />
);
