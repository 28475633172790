import React from 'react';
import ReactModal from 'react-modal';
import { Button } from 'js/components/ui/Button/Button';

export type Props = Pick<
  ReactModal.Props,
  'isOpen' | 'onRequestClose' | 'children'
> & {
  hideCloseButton?: boolean;
};

export const ModalPresenter: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  children,
  hideCloseButton,
}) => {
  return (
    <ReactModal
      className="p_modal__content"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      ariaHideApp={false}
    >
      {!hideCloseButton && (
        <Button
          className="p_modal__close-button"
          size="small"
          width={70}
          callback={onRequestClose}
        >
          閉じる
        </Button>
      )}
      {children}
    </ReactModal>
  );
};
