import React from 'react';
import {
  Props as SlideBannerThumbnailItemProps,
  SlideBannerThumbnailItem,
} from 'js/components/specific/top/SlideBanner/SlideBannerThumbnailItem';
import { MAX_ITEM_COUNT } from 'js/components/specific/top/SlideBanner/SlideBanner/const';

export type Props = {
  list: SlideBannerThumbnailItemProps[];
};

export const SlideBannerThumbnailListPresenter: React.FC<Props> = ({
  list,
}) => (
  <ul className="p_slide-banner__thumbnail-list">
    {list.map(
      (item) =>
        item.index < MAX_ITEM_COUNT && (
          <SlideBannerThumbnailItem {...item} key={item.itemName} />
        ),
    )}
  </ul>
);
