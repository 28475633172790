import React from 'react';
import classNames from 'classnames';
import isChromatic from 'chromatic/isChromatic';
import { AffiliateItemType } from 'js/types/affiliate';
import { Modal, Props as ModalProps } from 'js/components/ui/Overlay/Modal';
import { PointPattern } from 'js/components/common/Point/PointPattern';

export type Props = {
  item: AffiliateItemType;
  featureText?: string;
  isOpen: boolean;
} & Pick<ModalProps, 'onRequestClose'>;

export const ChomedamaPopupPresenter: React.FC<Props> = ({
  item,
  featureText,
  isOpen,
  onRequestClose,
}) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} hideCloseButton={true}>
    <div className="chomedama-popup">
      <img
        className="chomedama-popup__intensive-line"
        src="/top/img/chomedama/intensive_line.svg"
        alt=""
        width="200"
        height="200"
        onClick={onRequestClose}
        style={{ visibility: isChromatic() ? 'hidden' : 'visible' }}
      />
      <img
        className="chomedama-popup__navic"
        src="/top/img/chomedama/navic.svg"
        alt="ナビック"
        width="100"
        height="110"
      />
      <img
        className="chomedama-popup__logo chromatic-ignore"
        src="/top/img/chomedama/title.svg"
        alt="本日の超目玉"
        width="440"
        height="168"
      />
      <a
        className={classNames(
          'chomedama-popup__link',
          'c_reset',
          'c_hover-pop',
        )}
        href={item.linkUrl}
      >
        <div className="chomedama-popup__outer-frame">
          {featureText && (
            <p className="chomedama-popup__feature-text">{featureText}</p>
          )}
          <div
            className={classNames(
              'chomedama-popup__inner-frame',
              `${item.unit ? 'type-affiliate' : 'type-banner'}`,
            )}
          >
            {item.unit ? (
              /* アフィリエイト案件 */
              <div className="chomedama-popup__detail-wrapper">
                <img
                  className="chomedama-popup__image"
                  src={item.imageUrl}
                  alt={item.itemName}
                  width="200"
                  height="200"
                />
                <div className="chomedama-popup__detail">
                  <p className="chomedama-popup__name">{item.itemName}</p>
                  <p className="chomedama-popup__condition">{item.condition}</p>
                  <PointPattern
                    baseClassName="chomedama-popup"
                    regularPoint={item.regularPoint}
                    campaignPoint={item.campaignPoint}
                    unit={item.unit}
                    beforeSize={'medium'}
                    afterSize={'xx-large'}
                  />
                  <div className="chomedama-popup__button-wrapper">
                    <button
                      type="button"
                      className="chomedama-popup__check-button"
                    >
                      今すぐチェック
                    </button>
                    <button
                      className="chomedama-popup__close-button"
                      type="button"
                      onClick={onRequestClose}
                    >
                      閉じる
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              /* バナーのみ */
              <>
                <img
                  className="chomedama-popup__wide-image"
                  src={item.imageUrl}
                  alt={item.itemName}
                />
                <div className="chomedama-popup__button-wrapper">
                  <button
                    type="button"
                    className="chomedama-popup__check-button"
                  >
                    今すぐチェック
                  </button>
                  <button
                    className="chomedama-popup__close-button"
                    type="button"
                    onClick={onRequestClose}
                  >
                    閉じる
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </a>
    </div>
  </Modal>
);
