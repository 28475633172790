import React from 'react';
import classNames from 'classnames';

export type Props = {
  baseClassName?: string;
};

export const BannerSkeletonItemPresenter: React.FC<Props> = ({
  baseClassName,
}) => (
  <div className={classNames({ [`${baseClassName}__outer`]: baseClassName })}>
    <div
      className={classNames({ [`${baseClassName}__inner`]: baseClassName })}
    />
  </div>
);
