import React from 'react';
import {
  RankingPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Ranking/presenter';

export type Props = PresenterProps;

export const Ranking: React.FC<Props> = (props) => {
  return <RankingPresenter {...props} />;
};
