import { useState, useEffect, useCallback } from 'react';
import { useLocalStorage } from 'js/hooks/useLocalStorage';

type ChomedamaStatus = 'popup' | 'panel-open' | 'panel-collapse';

// 前回ページ離脱時のステータスと今回設定する初期ステータス
const initialStatusBylast = new Map<ChomedamaStatus, ChomedamaStatus>([
  [undefined, 'popup'], // 本日初訪問
  ['popup', 'panel-open'],
  ['panel-open', 'panel-collapse'],
  ['panel-collapse', 'panel-collapse'],
]);

type StatusWithDate = {
  id: string;
  status: ChomedamaStatus;
};

export const useChomedamaStatus = (id: string) => {
  const {
    value: localstorageValue,
    save: saveLocalStorage,
    isReady,
  } = useLocalStorage<StatusWithDate>('chomedama_status');

  const [status, setStatus] = useState<ChomedamaStatus>();
  const [initialized, setInitialized] = useState(false);

  const save = useCallback(
    (current: ChomedamaStatus) => {
      setStatus(current);
      saveLocalStorage({
        id: id,
        status: current,
      });
    },
    [setStatus, saveLocalStorage, id],
  );

  useEffect(() => {
    if (!isReady || initialized) {
      // localStorageの準備ができていない/初期化済み
      return;
    }

    // 前回TOPページ離脱時のChomedamaStatusを元に今回の初期ステータスを決定
    const lastStatus =
      !localstorageValue || localstorageValue.id !== id
        ? undefined
        : localstorageValue.status;
    save(initialStatusBylast.get(lastStatus));
    setInitialized(true);
  }, [localstorageValue, isReady, initialized, setInitialized, save, id]);

  return { status, save };
};
