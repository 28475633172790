import firebase from 'firebase/app';
import 'firebase/messaging';

type FirebaseConfigurationType = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

export const request = (
  firebaseConfigurationData: string,
  firebaseCertificate: string,
  csrfToken: string,
  env: string,
) => {
  const firebaseConfiguration = JSON.parse(
    firebaseConfigurationData,
  ) as FirebaseConfigurationType;
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfiguration);
  }

  if (!firebase.messaging.isSupported()) {
    return;
  }

  /**
   *  FCMトークンをサーバに送る
   */
  async function sendTokenToServer() {
    const messaging = firebase.messaging();

    let sw;

    if (env === 'prod') {
      sw = await navigator.serviceWorker.register(
        '/web_push_notification/sw.js',
      );
    } else {
      sw = await navigator.serviceWorker.register(
        '/web_push_notification/dev-sw.js',
      );
    }

    const fcmToken = await messaging.getToken({
      vapidKey: firebaseCertificate,
      serviceWorkerRegistration: sw,
    });
    const options = {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ fcmToken, csrfToken }),
    };
    return fetch('/api/fcm_registration_token/insert/', options);
  }

  if (Notification.permission === 'granted') {
    void sendTokenToServer();
  }
};
