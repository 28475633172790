import React from 'react';
import { Button } from 'js/components/ui/Button/Button';
import { Navic } from 'js/components/ui/Icon/Navic';

export type Props = {
  needSignupCID: boolean;
};

export const SignUpAppealPresenter: React.FC<Props> = ({ needSignupCID }) => (
  <div id="signup-appeal" className="signup-appeal">
    <section className="signup-appeal__background">
      <p className="signup-appeal__copy">ポイント貯めてお小遣い稼ぎ!</p>
      <div className="signup-appeal__inner">
        <h1 className="signup-appeal__title">
          ポイ活するなら
          <wbr />
          ECナビ!
        </h1>
        <ul className="signup-appeal__coins">
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
          <li className="signup-appeal__coin" />
        </ul>
        <section className="signup-appeal-exchange">
          <h2 className="signup-appeal-exchange__title">
            貯まったポイントは
            <wbr />
            <span className="signup-appeal-exchange__title-pex">
              ポイント交換サイトPeX
            </span>
            を通して色々交換
          </h2>
          <Navic className="signup-appeal-exchange__navic" faceType={'wink'} />
          <div className="signup-appeal-exchange__wrapper">
            <p className="pex-logo">
              <img
                className="pex-logo__image"
                src="https://static.pex.jp.vmimg.jp/img/logo/pex.png"
                alt="PeX"
                width="304"
                height="124"
              />
            </p>
            <div className="exchange-example">
              <ul className="exchange-example__list">
                <li className="exchange-example__item">
                  <img
                    className="exchange-example__image"
                    src="/top/img/point_exchange_money.png"
                    alt="現金"
                    width="108"
                    height="67"
                  />
                </li>
                <li className="exchange-example__item">
                  <img
                    className="exchange-example__image"
                    src="https://static.pex.jp.vmimg.jp/img/logo/spend/paypay.png"
                    alt="PayPayマネーライト"
                    width="240"
                    height="88"
                  />
                </li>
                <li className="exchange-example__item">
                  <img
                    className="exchange-example__image"
                    src="https://static.pex.jp.vmimg.jp/img/logo/spend/amazon.png"
                    alt="Amazonギフトカード"
                    width="240"
                    height="43"
                  />
                </li>
                <li className="exchange-example__item">
                  <img
                    className="exchange-example__image"
                    src="https://static.pex.jp.vmimg.jp/img/logo/spend/rakuten_super_point.png"
                    alt="楽天ポイント"
                    width="180"
                    height="180"
                  />
                </li>
                <li className="exchange-example__item">
                  <img
                    className="exchange-example__image"
                    src="https://static.pex.jp.vmimg.jp/img/logo/spend/tpointjapan.png"
                    alt="Vポイント"
                    width="210"
                    height="56"
                  />
                </li>
              </ul>
              <ul className="exchange-example__second-list">
                <li className="exchange-example__second-item">現金</li>
                <li className="exchange-example__second-item">ギフト券</li>
                <li className="exchange-example__second-item">電子マネー</li>
                <li className="exchange-example__second-item">マイレージ</li>
                <li className="exchange-example__second-item">提携ポイント</li>
              </ul>
            </div>
          </div>
        </section>
        <ul className="signup-appeal-safety">
          <li className="signup-appeal-safety__item">
            <p className="signup-appeal-safety__text">
              運営実績
              <br />
              <span className="signup-appeal-safety__emphasis">20年</span>
              <span className="signup-appeal-safety__br">以上</span>
            </p>
          </li>
          <li className="signup-appeal-safety__item">
            <p className="signup-appeal-safety__text">
              <span className="signup-appeal-safety__emphasis">
                東証プライム上場
              </span>
              <br />
              グループ会社が
              <span className="signup-appeal-safety__br">運営</span>
            </p>
          </li>
          <li className="signup-appeal-safety__item">
            <p className="signup-appeal-safety__text">
              登録会員
              <br />
              <span className="signup-appeal-safety__emphasis">900万人</span>
              <span className="signup-appeal-safety__br">以上</span>
            </p>
          </li>
        </ul>
        <Button
          isPrimary={true}
          size={'xx-large'}
          sizeType={'relative'}
          className="signup-appeal__button"
          href={
            needSignupCID ? '/ac/check/?CID=10004&BID=001&SID=102' : '/signup/'
          }
        >
          簡単1分！無料会員登録
        </Button>
      </div>
    </section>
  </div>
);
