import React from 'react';
import classNames from 'classnames';
import { BannerSkeletonItem } from 'js/components/common/Banner/BannerSkeletonItem';
import { BannerType } from 'js/components/common/Banner/BannerItem/type';
import { ColumnType } from 'js/types/gridList';
import { range } from 'js/utils/helper';

const baseClassName = 'in-frame-skeleton-banner';

export type Props = {
  count: number;
  column?: ColumnType;
  type?: BannerType;
};

export const InFrameBannerSkeletonListPresenter: React.FC<Props> = ({
  count,
  column = 'auto',
  type = 'square',
}) => (
  <div
    className={classNames(
      baseClassName,
      'c_contents-group',
      'p_grid-list',
      `column-${column}`,
      `type-${type}`,
    )}
  >
    {range(1, count).map((item, index) => (
      <BannerSkeletonItem key={index} baseClassName={baseClassName} />
    ))}
  </div>
);
