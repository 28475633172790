import React from 'react';
import { ReactTabsFunctionComponent } from 'react-tabs';
import {
  TabsPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Tab/Tabs/presenter';

export type Props = PresenterProps;

export const Tabs: ReactTabsFunctionComponent<Props> = ({
  children,
  ...props
}) => <TabsPresenter {...props}>{children}</TabsPresenter>;
Tabs.tabsRole = 'Tabs';
