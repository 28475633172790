import React from 'react';

// zucksリターゲティングタグhttps://adingo-redmine.sjdc.voyage/issues/62859
export const ZucksRetargetingTagPresenter: React.FC = () => (
  <div
    dangerouslySetInnerHTML={{
      __html: `
<script type="text/javascript" src="//j.zucks.net.zimg.jp/rt?aid=fea56ad577" async></script>
    `,
    }}
  />
);
