import React from 'react';
import {
  TeibanServicePresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/TeibanService/presenter';

export type Props = PresenterProps;

export const TeibanService: React.FC<Props> = (props) => {
  return <TeibanServicePresenter {...props} />;
};
