import React from 'react';
import {
  SlideBannerPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/SlideBanner/SlideBanner/presenter';

export type Props = PresenterProps;

export const SlideBanner: React.FC<Props> = ({ list }) => (
  <SlideBannerPresenter list={list} />
);
