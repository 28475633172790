import React, { useState } from 'react';

// react-slickのスライダー要素がaタグの場合にスライドが出来ない
// その問題を解決するためのハンドラーを定義する
// ref: https://github.com/akiran/react-slick/issues/1952#issuecomment-752950329
export const useHandleSlideEvent = (
  isActive: boolean,
  slickGoToCurrent: () => void,
) => {
  const [clientXonMouseDown, setClientXonMouseDown] = useState<number>();
  const [clientYonMouseDown, setClientYonMouseDown] = useState<number>();
  const handleOnMouseDown = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      e.preventDefault();
    }
    if (!isActive) {
      e.preventDefault();
    }
    slickGoToCurrent();
  };

  return { handleOnMouseDown, handleOnClick };
};
