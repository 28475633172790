import React from 'react';
import classNames from 'classnames';
import { Heading } from 'js/components/ui/Heading';

export type Props = {
  className?: string;
  heading: string;
  children: React.ReactNode;
  contentsLink?: string;
};

export const AppealFramePresenter: React.FC<Props> = ({
  className = '',
  heading,
  children,
  contentsLink = '',
}) => (
  <section className={classNames(className, 'c_section')}>
    <Heading type="level-2">{heading}</Heading>
    {children}
    {contentsLink && (
      <a className="c_more-link" href={contentsLink}>
        もっと見る
      </a>
    )}
  </section>
);
