import React from 'react';
import {
  FirstEnquetePresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/FirstEnquete/presenter';

export type Props = PresenterProps;

export const FirstEnquete: React.FC<Props> = ({
  isNewUser,
  status,
  questions,
  nextQuestionStartHour,
  nextQuestionHourLeft,
  nextBonusHourLeft,
  isBonus,
  sessionIdHash,
}) => (
  <FirstEnquetePresenter
    isNewUser={isNewUser}
    status={status}
    questions={questions}
    nextQuestionStartHour={nextQuestionStartHour}
    nextQuestionHourLeft={nextQuestionHourLeft}
    nextBonusHourLeft={nextBonusHourLeft}
    isBonus={isBonus}
    sessionIdHash={sessionIdHash}
  />
);
