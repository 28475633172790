import { useStaticDataRequest } from 'js/hooks/useRequest';
import { useTimestamp } from 'js/hooks/pages/layout/useTimestamp';
import { useAlreadyRead } from 'js/hooks/pages/useAlreadyRead';
import { useExpirationInformation } from 'js/hooks/pages/useExpirationInformation';
import { useUserInfo } from 'js/hooks/useUserInfo';
import { InformationElementType } from 'js/components/specific/top/Information/InformationList/type';

export interface InformationResponse {
  ID: string;
  TITLE: string;
  BODY?: string;
  POINT?: string;
  URL: string;
  TARGET_BLANK: string;
  IMAGE_FILE?: string;
  PUBLISH_AT: string;
  EXPIRE_AT: string;
  IS_UPDATED_TODAY: string;
  CK: string;
}

export const useInformation = (
  device: string,
  fallbackData: InformationResponse[],
) => {
  const timestamp = useTimestamp();
  const { ids, save } = useAlreadyRead('read_important_informations');
  const { userInfo } = useUserInfo();
  const expirationInformation = useExpirationInformation(
    timestamp,
    userInfo?.pointLimit.limitPoint,
    userInfo?.pointLimit.nextLimitDate,
  );
  const { data } = useStaticDataRequest<InformationResponse[]>(
    timestamp
      ? [
          '/api/information/',
          {
            params: {
              c: 2, // category
              d: device === 'pc' ? 2 : 3, // device
              t: timestamp, // time
            },
          },
        ]
      : null,
    fallbackData,
  );
  const informationList = data
    ?.map<InformationElementType>((info) => ({
      id: info.ID,
      children: info.TITLE,
      linkUrl: info.URL,
      type: 'info',
      canClose: true,
      publishAt: new Date(info.PUBLISH_AT),
    }))
    .concat(expirationInformation ? [expirationInformation] : [])
    .filter((parsedInfo) => !ids.includes(parsedInfo.id));
  return {
    informationList: informationList ?? [],
    readInformation: save,
  };
};
