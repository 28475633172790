import React from 'react';

export const IdentificationNoticeStep4Presenter: React.FC = () => (
  <div className="identification-step c_emphasis-info">
    <p className="c_emphasis-info__heading">メールアドレスリセット審査中</p>
    <ul className="identification-step__list">
      <li className="identification-step__item">
        <span className="identification-step__number">1</span>
        <p className="identification-step__label">申請</p>
      </li>
      <li className="identification-step__item">
        <span className="identification-step__number">2</span>
        <p className="identification-step__label">審査(7日間)</p>
      </li>
      <li className="identification-step__item">
        <span className="identification-step__number">3</span>
        <p className="identification-step__label">変更受付</p>
      </li>
      <li className="identification-step__item is_active">
        <span className="identification-step__number">4</span>
        <p className="identification-step__label">本人認証</p>
      </li>
    </ul>
    <a href="/security/twofactor/?content_id=2" className="c_button">
      本人認証登録
    </a>
    <ul className="c_emphasis-info__list">
      <li className="c_emphasis-info__item">
        プロフィール変更やポイント交換ができないため、本人認証登録を完了させてください。
      </li>
    </ul>
  </div>
);
