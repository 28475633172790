import React from 'react';
import { IdentificationNoticeStep2 } from 'js/components/specific/top/IdentificationNotice/IdentificationNoticeStep2';
import { IdentificationNoticeStep3 } from 'js/components/specific/top/IdentificationNotice/IdentificationNoticeStep3';
import { IdentificationNoticeExpired } from 'js/components/specific/top/IdentificationNotice/IdentificationNoticeExpired';
import { IdentificationNoticeStep4 } from 'js/components/specific/top/IdentificationNotice/IdentificationNoticeStep4';
import { EmailChangeDateType } from 'js/components/specific/top/IdentificationNotice/type';

export type Props = {
  isChanged: boolean;
  isWithinWaitingTerm: boolean;
  isWithinValidTerm: boolean;
  isRegisteredPhone: boolean;
  isExpiredTerm: boolean;
  canShowExpiredMessage: boolean;
  canShow2faMessage: boolean;
  emailChangeDate?: EmailChangeDateType;
};

export const IdentificationNoticePresenter: React.FC<Props> = ({
  isChanged,
  isWithinWaitingTerm,
  isWithinValidTerm,
  isRegisteredPhone,
  isExpiredTerm,
  canShowExpiredMessage,
  canShow2faMessage,
  emailChangeDate,
}) => {
  if (!isChanged) {
    if (isWithinWaitingTerm) {
      return <IdentificationNoticeStep2 {...emailChangeDate} />;
    } else if (isWithinValidTerm) {
      return <IdentificationNoticeStep3 {...emailChangeDate} />;
    } else if (isExpiredTerm && canShowExpiredMessage) {
      return <IdentificationNoticeExpired />;
    }
  } else if (!isRegisteredPhone && canShow2faMessage) {
    return <IdentificationNoticeStep4 />;
  }
  return null;
};
