import React from 'react';
import {
  ModalPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Overlay/Modal/presenter';

export type Props = PresenterProps;

export const Modal: React.FC<Props> = ({ ...props }) => (
  <ModalPresenter {...props} />
);
