import React from 'react';
import {
  IdentificationNoticeStep2Presenter,
  Props as PresenterProps,
} from 'js/components/specific/top/IdentificationNotice/IdentificationNoticeStep2/presenter';

type Props = PresenterProps;

export const IdentificationNoticeStep2: React.FC<Props> = ({
  reception_at,
  start_at,
  end_at,
}) => (
  <IdentificationNoticeStep2Presenter
    reception_at={reception_at}
    start_at={start_at}
    end_at={end_at}
  />
);
