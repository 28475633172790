import React from 'react';
import { ReactTabsFunctionComponent } from 'react-tabs';
import {
  TabPanelPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Tab/TabPanel/presenter';

export type Props = PresenterProps;

export const TabPanel: ReactTabsFunctionComponent<Props> = ({
  children,
  ...props
}) => <TabPanelPresenter {...props}>{children}</TabPanelPresenter>;
TabPanel.tabsRole = 'TabPanel';
